import React from "react";
import BlockContent from "../components/blockContent";
import Feedback from "../components/feedback";
import ButtonComponent from "../components/button";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {ReactComponent as LocationIcon} from "../static/svg/location.svg";
import {ReactComponent as CallCentreIcon} from "../static/svg/call-centre.svg";
import {ReactComponent as EmailBorder} from "../static/svg/emailBorder.svg";

import {phone_list} from "../data/phone_data";

const useStyles = makeStyles((theme) => ({
  main: {
    // textAlign: "left"
  },
  mainTitle: {
    padding: "10px",
    background: "#F3F4F4",
    color: "#484d62",
    margin: "20px 0px 10px 0px",
    textAlign: "center"
  },
  mainItem: {
    margin: "10px"
  },
  mainDescription: {
    margin: "20px auto"
  },
  list: {
    padding: "20px"
  },
  titleBlock: {
    color: "#484D62",
    fontSize: "2rem",
    fontFamily: "Segoe UI, sans-serif",
    fontWeight: "bold",
    padding: "0px 0px 30px 20px"
  },
  title: {
    fontSize: "5rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "3rem"
    }
  },
  content: {
    backgroundColor: "#F3F4F4",
    borderTop: "1px solid #D0D7DD"
  },
  blockImg: {
    border: "1px solid #f3f4f4",
    textAlign: "center",
    borderRadius: "7px",
    background: "#f3f4f4",
    padding: "20px",
    margin: "20px"
  },
  linkButton: {
    width: "100%",
    display: "block",
    textAlign: "center"
  },
  mainPhone: {
    width: "100%",
    margin: "10px",
  },
  mainPhoneDescription: {
    fontSize: "1.1rem",
    padding: "0px 0px 10px 0px",
    margin: "0px 0px 20px 0px",
    borderBottom: "1px solid #5e5e5e"
  },
  flexContainer: {
    listStyleType: 'none',
    //display: 'flex',
    //flexFlow: "row wrap",
    display: "grid",
    //gridTemplateRows: "1fr auto auto",
    //gridTemplateColumns: "1fr auto auto",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr));",
    gridGap: "2vw",
    textAlign: "left",

  },
  itemList: {

  },
  itemPhone: {
    fontSize: '1.4rem',
    padding: '0px 10px 0px 0px'
  },
  itemName: {

  },
}));

export default function Contact() {
  const classes = useStyles();
  return (
    <>
      <BlockContent title="Контакты" bigFont>
        <Grid container className={classes.main}>
          <Grid item xs={12} sm className={classes.mainItem}>
            <LocationIcon height="100px" />
            <Typography className={classes.mainTitle}>Адрес</Typography>
            <ButtonComponent
              fullWidth
              title="ПН-ПТ с 9-00 до 17-00 "
            />
            <Typography align="left" className={classes.mainDescription}>
              620073 Свердловская область г. Екатеринбург, ул. Крестинского, д.46А
              офис. 708.
            </Typography>
          </Grid>
          <Grid item xs className={classes.mainItem}>
            <EmailBorder height="100px" />
            <Typography className={classes.mainTitle}>
              Email: info@kavozvrat.ru
            </Typography>
            <ButtonComponent
              fullWidth
              title={<a href="mailto:info@kavozvrat.ru" className={classes.linkButton}>Написать</a>}
            />
            <Typography align="left" className={classes.mainDescription}>
              Пишите нам на электронную почту, мы всегда рады ответить на ваши
              вопросы.
            </Typography>
          </Grid>
          <Grid item xs={12} sm className={classes.mainItem}>
            <CallCentreIcon height="100px" />
            <Typography className={classes.mainTitle}>
              8-800-700-20-68 | +7 (963) 853-51-84
            </Typography>
            <ButtonComponent
              fullWidth
              title={<a href="tel:79638535184" className={classes.linkButton}>Позвонить</a>}
            />
            <Typography align="left" className={classes.mainDescription}>
              Позвонив нам, Вы услышите очень вежливого и приятного собеседника,
              который ответит вам на Ваши вопросы.
            </Typography>
          </Grid>
        </Grid>
      </BlockContent>
      <BlockContent title="Номера телефонов" className={classes.content}>
        <div className={classes.mainPhone}>
          <div className={classes.mainPhoneDescription}>Все номера ООО ПКО "Возврат", с которых осуществляется общение с должниками.</div>
          <ul className={classes.flexContainer}>
            {
              phone_list.map(({name, phone}) => {
                console.log(name, phone)
                return (
                  <li className={classes.itemList}>
                    <span className={classes.itemPhone}>{phone}</span>
                    <span className={classes.itemName}>{name}</span>
                  </li>
                )
              })
            }
            {/*<li className="flex-item">8007002068</li>
          <li className="flex-item">9617767489</li>
          <li className="flex-item">9617768159</li>
          <li className="flex-item">9617768669</li>
          <li className="flex-item">5</li>
          <li className="flex-item">6</li>*/}
          </ul>
        </div>
      </BlockContent>
    </>
  );
}
